import { clean } from '@leaf/form'
import type * as Types from '@leaf/types'

import { graphqlClient } from '@/api'
import {
  GetConstellationDocument,
  type GetConstellationQuery,
} from '@/features/constellation/constellation-details.api.generated'

type ConstellationDetailsQueryReturnType = Types.NoUndefined<
  GetConstellationQuery['constellationsByPk']
>

const constellationDetailsQuery = (id: string) => ({
  queryFn: async () => {
    const response = await graphqlClient.request(GetConstellationDocument, { id })
    const data = clean(response) as GetConstellationQuery
    const constellation = (data.constellationsByPk || {}) as ConstellationDetailsQueryReturnType
    return constellation
  },
  queryKey: [`constellation-details-${id}`],
})

export { constellationDetailsQuery }
export type { ConstellationDetailsQueryReturnType }
