import { WifiOff as ErrorIcon } from '@mui/icons-material'

import { Styled, Text } from '@leaf/components'

const ErrorPage = () => {
  return (
    <Styled.Center>
      <Text.H1>
        <ErrorIcon />
      </Text.H1>

      <Text.Body1>Something went terribly wrong.</Text.Body1>

      <Text.Body1>
        You should contact us{' '}
        <a href='mailto:support@leaflogistics.com'>support@leaflogistics.com</a>
      </Text.Body1>
    </Styled.Center>
  )
}

export { ErrorPage }
