import { memo, useEffect } from 'react'
import { Outlet, useNavigation } from 'react-router-dom'

import { Grid } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useShallow } from 'zustand/react/shallow'

import { Snackbar, Spinner } from '@leaf/components'
import { CompanyTypes, Roles } from '@leaf/constants'

import { auth } from '@/api'
import { Changelog } from '@/features'
import { Navigation } from '@/navigation/navigation'
import { LayoutHeader } from '@/navigation/pages/layout-header'
import { useStore } from '@/store'

const LayoutPage = () => {
  const { client, isRedirectToLogin, user } = auth

  const shouldRedirect =
    !isRedirectToLogin &&
    user?.app_metadata.role !== Roles.LE_ADMIN &&
    user?.app_metadata.company_type === CompanyTypes.CARRIER
  useQuery({
    enabled: shouldRedirect,
    queryFn: async () => {
      await client.logout({
        logoutParams: {
          returnTo: 'https://carrier.leaflogistics.com',
        },
      })
      return {}
    },
    queryKey: ['redirect-carrier'],
  })
  const { app_metadata: metadata, firstname, lastname, uid } = user || {}

  const [userState, updateUser, showChangelog, toggleChangelog, snackbars, removeSnackbar] =
    useStore(
      useShallow((state) => [
        state.user,
        state.updateUser,
        state.showChangelog,
        state.toggleChangelog,
        state.snackbars,
        state.removeSnackbar,
      ]),
    )

  const isAdmin = metadata?.role === Roles.LE_ADMIN
  useEffect(() => {
    if (!isRedirectToLogin) {
      updateUser({
        companyId: isAdmin ? 60 : Number(metadata?.company_id),
        id: Number(uid),
        isAdmin,
        name: `${firstname} ${lastname}`,
      })
    }
  }, [])

  const navigation = useNavigation()
  // Wait for the shipper to be set
  if (!userState.companyId || shouldRedirect || isRedirectToLogin) {
    return <Spinner page />
  }
  const navigationLoading = navigation.state === 'loading'

  return (
    <Grid sx={{ display: 'flex', height: '100vh' }}>
      <Navigation toggleChangelog={toggleChangelog} />

      <Changelog onClose={toggleChangelog} open={showChangelog} />

      <Grid sx={{ flexGrow: 1, overflow: 'auto' }}>
        <LayoutHeader impersonation={isAdmin && !isRedirectToLogin} />

        {navigationLoading ? <Spinner page /> : <Outlet />}
      </Grid>

      <Snackbar removeSnackbar={removeSnackbar} snackbars={snackbars} />
    </Grid>
  )
}

const Memo = memo(LayoutPage)
export { Memo as LayoutPage }
