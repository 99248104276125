import { memo } from 'react'

import {
  Explore as AdaptIcon,
  Notes as ChangelogIcon,
  Business as CompanyIcon,
  AssignmentTurnedIn as ContractsIcon,
  Dashboard as DashboardIcon,
  ExitToApp as LogoutIcon,
  Policy as PoliciesIcon,
} from '@mui/icons-material'
import { useQuery } from '@tanstack/react-query'
import { useShallow } from 'zustand/react/shallow'

import { Drawer } from '@leaf/drawer'
import type * as Types from '@leaf/types'

import { auth, restClient } from '@/api'
import { useStore } from '@/store'

const PARTY_CITY_ID = 56612

const getItems = (
  user: Types.User,
  dashboards: { category: string }[],
  toggleChangelog: Types.Noop,
) => {
  const hidden = [PARTY_CITY_ID].includes(user.companyId!) && !user.isAdmin
  const notice = [PARTY_CITY_ID].includes(user.companyId!) && user.isAdmin

  return [
    ...(dashboards.length > 1
      ? [
          {
            icon: DashboardIcon,
            subs: dashboards.map((d) => {
              return {
                text: d?.category,
                to: `/dashboard/${d.category}`,
              }
            }),
            text: 'dashboard',
            to: '/dashboard',
          },
        ]
      : [
          {
            icon: DashboardIcon,
            text: 'dashboard',
            to: '/dashboard',
          },
        ]),
    {
      hidden,
      icon: CompanyIcon,
      id: 'e2e-company-menu',
      notice,
      subs: [
        {
          text: 'Overview',
          to: '/company/overview',
        },
        {
          id: 'e2e-company-network-menu-item',
          text: 'Network',
          to: '/company/network',
        },
      ],
      text: 'Company',
    },
    {
      hidden,
      icon: AdaptIcon,
      id: 'e2e-adapt-menu',
      notice,
      subs: [
        { id: 'e2e-adapt-lanes-menu-item', text: 'Lanes', to: '/adapt/lanes' },
        {
          id: 'e2e-adapt-constellations-menu-item',
          text: 'Constellations',
          to: '/adapt/constellations',
        },
        {
          id: 'e2e-adapt-network-moves-menu-item',
          text: 'Network Moves',
          to: '/adapt/network-moves',
        },
      ],
      text: 'adapt',
    },
    {
      icon: ContractsIcon,
      id: 'e2e-contracts-menu-item',
      text: 'contracts',
      to: '/contracts',
    },
    {
      icon: ChangelogIcon,
      id: 'e2e-changelog-menu-item',
      onClick: toggleChangelog,
      text: 'changelog',
    },
    {
      icon: PoliciesIcon,
      id: 'e2e-policies-menu-item',
      text: 'policies',
      to: '/policies',
    },
    {
      icon: LogoutIcon,
      onClick: async () => {
        const options = {
          logoutParams: {
            returnTo: window.location.origin,
          },
        }
        await auth.client!.logout(options)
      },
      text: 'logout',
    },
  ]
}

const Navigation = ({ toggleChangelog = () => {} }: { toggleChangelog?: Types.Noop }) => {
  const [user, showDrawer, toggleDrawer] = useStore(
    useShallow((state) => [state.user, state.showDrawer, state.toggleDrawer]),
  )
  const { data, isLoading } = useQuery({
    queryFn: () =>
      restClient.get(`tendering/holistics`, {
        headers: {
          'X-Leaf-Auth-Company-Id': user.companyId!,
          'X-Leaf-Auth-Company-Type': 'SHIPPER',
        },
      }),
    queryKey: [`holistics-${user.companyId}`],
  })

  const items = getItems(user, isLoading ? [] : data?.data, toggleChangelog)

  return <Drawer items={items} show={showDrawer} title='leaf' toggle={toggleDrawer} />
}

const Memo = memo(Navigation)
export { Memo as Navigation }
