import { Box, styled } from '@mui/material'

const AdaptData = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.38);
  margin-top: 6em;
  font-weight: 500;
`
export { AdaptData }
