// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetContractByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type GetContractByIdQuery = { __typename?: 'query_root', contractsByPk?: { __typename?: 'contracts', contractType?: string, isFleet: boolean, startDate?: any, contractStatus: string, endDate?: any, linehaulRpm?: any, minVolume?: number, includeWeekends?: boolean, maxVolume?: number, volumeType: string, volumeFrequency?: string, pricingMechanism?: string, equipmentType: { __typename?: 'equipmentTypes', name: string }, fuelSurchargeSchedule?: { __typename?: 'fuelSurchargeSchedules', name: string }, contractAccessorials: Array<{ __typename?: 'contractAccessorials', accessorial: { __typename?: 'accessorials', name: string } }>, contractRoutes: Array<{ __typename?: 'contractRoutes', route: { __typename?: 'routingRoutes', tripType: string, legs: Array<{ __typename?: 'routingLegs', lane: { __typename?: 'routingLanes', shipperId?: any, originName?: string, destinationName?: string, leafMiles?: number, path?: any } }> } }> } };



export const GetContractByIdDocument = `
    query GetContractById($id: uuid!) {
  contractsByPk(id: $id) {
    contractType
    isFleet
    startDate
    contractStatus
    endDate
    linehaulRpm
    minVolume
    includeWeekends
    maxVolume
    volumeType
    volumeFrequency
    pricingMechanism
    equipmentType {
      name
    }
    fuelSurchargeSchedule {
      name
    }
    contractAccessorials {
      accessorial {
        name
      }
    }
    contractRoutes(where: {isPrimary: {_eq: true}}) {
      route {
        tripType
        legs(orderBy: {sequence: ASC}) {
          lane {
            shipperId
            originName
            destinationName
            leafMiles
            path
          }
        }
      }
    }
  }
}
    `;

export const useGetContractByIdQuery = <
      TData = GetContractByIdQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetContractByIdQueryVariables,
      options?: UseQueryOptions<GetContractByIdQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetContractByIdQuery, TError, TData>(
      ['GetContractById', variables],
      fetcher<GetContractByIdQuery, GetContractByIdQueryVariables>(client, GetContractByIdDocument, variables, headers),
      options
    )};

useGetContractByIdQuery.document = GetContractByIdDocument;

useGetContractByIdQuery.getKey = (variables: GetContractByIdQueryVariables) => ['GetContractById', variables];


useGetContractByIdQuery.fetcher = (client: GraphQLClient, variables: GetContractByIdQueryVariables, headers?: RequestInit['headers']) => fetcher<GetContractByIdQuery, GetContractByIdQueryVariables>(client, GetContractByIdDocument, variables, headers);
