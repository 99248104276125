import { Card, CardContent, Grid } from '@mui/material'

import { CompanyAvatar, DataMissing, DataRow, Text } from '@leaf/components'

import { AdaptData } from '@/features/company/company-overview.styled'

type MetricType = {
  name: string
  value: string
}
type CompanyOverviewMetricsType = {
  metrics: MetricType[]
  runDate?: string
  show: boolean
  windowEndDate?: string
  windowStartDate?: string
}

type CompanyOverviewMetricsProps = {
  data: CompanyOverviewMetricsType
  shipperId: number
}
const CompanyOverviewMetrics = ({ data, shipperId }: CompanyOverviewMetricsProps) => {
  return (
    <Card>
      <CardContent>
        <Grid item xs={12}>
          <Grid
            item
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '3em',
            }}
            xs={12}
          >
            <CompanyAvatar id={shipperId!} size={80} />
          </Grid>
        </Grid>

        {data.metrics?.length ? (
          <Grid item xs={12}>
            <>
              <Grid item xs={12}>
                {data.metrics.map((metric) => (
                  <DataRow key={metric.name} label={metric.name} value={metric.value} />
                ))}
              </Grid>

              {data.show && (
                <Grid item xs={12}>
                  <AdaptData>
                    <Text.Body1>Analyzed via Adapt on {data.runDate}</Text.Body1>
                    <Text.Body1>Based on historical shipments from</Text.Body1>
                    <Text.Body1>
                      {data.windowStartDate} to {data.windowEndDate}
                    </Text.Body1>
                  </AdaptData>
                </Grid>
              )}
            </>
          </Grid>
        ) : (
          <DataMissing />
        )}
      </CardContent>
    </Card>
  )
}

export { CompanyOverviewMetrics }
