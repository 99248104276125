import { Box, styled } from '@mui/material'
import Markdown from 'react-markdown'

import content from '@/features/policy/policies.md?raw'
import { useTitles } from '@/hooks'

const Page = styled(Box)`
  margin-left: 1em;
  margin-right: 3em;

  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
    margin-top: 2em;
    margin-bottom: 1em;
  }
  h3 {
    font-size: 1.25em;
    margin-top: 0.25em;
    margin-bottom: 0em;
  }
  ul {
    margin-top: 0.25em;
    padding-left: 1em;
  }
`

const Policies = () => {
  useTitles([{ label: 'Policies' }])

  return (
    <Page>
      <Markdown>{content}</Markdown>
    </Page>
  )
}

export { Policies }
