// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetContractsQueryVariables = Types.Exact<{
  shipperId: Types.Scalars['bigint']['input'];
}>;


export type GetContractsQuery = { __typename?: 'query_root', contracts: Array<{ __typename?: 'contracts', id: any, contractType?: string, isFleet: boolean, startDate?: any, endDate?: any, linehaulRpm?: any, maxVolume?: number, equipmentType: { __typename?: 'equipmentTypes', name: string }, contractAccessorials: Array<{ __typename?: 'contractAccessorials', accessorial: { __typename?: 'accessorials', name: string } }>, contractRoutes: Array<{ __typename?: 'contractRoutes', route: { __typename?: 'routingRoutes', tripType: string, legs: Array<{ __typename?: 'routingLegs', lane: { __typename?: 'routingLanes', originName?: string, destinationName?: string } }> } }> }> };



export const GetContractsDocument = `
    query GetContracts($shipperId: bigint!) {
  contracts(
    orderBy: {startDate: DESC}
    where: {_and: [{contractStatus: {_eq: "CONTRACTED"}}, {_or: [{buyerId: {_eq: $shipperId}}, {sellerId: {_eq: $shipperId}}]}]}
  ) {
    id
    contractType
    isFleet
    startDate
    endDate
    linehaulRpm
    maxVolume
    equipmentType {
      name
    }
    contractAccessorials {
      accessorial {
        name
      }
    }
    contractRoutes(where: {isPrimary: {_eq: true}}) {
      route {
        tripType
        legs(orderBy: {sequence: ASC}) {
          lane {
            originName
            destinationName
          }
        }
      }
    }
  }
}
    `;

export const useGetContractsQuery = <
      TData = GetContractsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetContractsQueryVariables,
      options?: UseQueryOptions<GetContractsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetContractsQuery, TError, TData>(
      ['GetContracts', variables],
      fetcher<GetContractsQuery, GetContractsQueryVariables>(client, GetContractsDocument, variables, headers),
      options
    )};

useGetContractsQuery.document = GetContractsDocument;

useGetContractsQuery.getKey = (variables: GetContractsQueryVariables) => ['GetContracts', variables];


useGetContractsQuery.fetcher = (client: GraphQLClient, variables: GetContractsQueryVariables, headers?: RequestInit['headers']) => fetcher<GetContractsQuery, GetContractsQueryVariables>(client, GetContractsDocument, variables, headers);
