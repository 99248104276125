import { useCallback, useState } from 'react'

import { Flag, Square } from '@mui/icons-material'
import { Box, Divider, Typography } from '@mui/material'
import { Layer, type MapLayerMouseEvent, Popup, Source } from 'react-map-gl'

import { Map, MapLegend, Spinner } from '@leaf/components'

import { graphqlClient } from '@/api'
import {
  useGetLeafNetworkQuery,
  useGetShipperNetworkQuery,
} from '@/features/company/company-network.api.generated'
import { useTitles } from '@/hooks'
import { useStore } from '@/store'

type HoverInfo = {
  destinationGeometry: GeoJSON.Polygon
  destinationName: string
  id: string
  lat: number
  lng: number
  miles: string
  originGeometry: GeoJSON.Polygon
  originName: string
}

const CompanyNetwork = () => {
  const { companyId } = useStore((state) => state.user)
  const [hoverInfo, setHoverInfo] = useState<HoverInfo | null>(null)

  useTitles([{ label: 'Company Network' }])

  const { data: shipperNetwork, isLoading: isShipperNetworkLoading } = useGetShipperNetworkQuery(
    graphqlClient,
    { shipperId: companyId },
    { select: (response) => response.shipperNetworks[0]?.network },
  )

  const { data: leafNetwork, isLoading: isLeafNetworkLoading } = useGetLeafNetworkQuery(
    graphqlClient,
    {},
    { select: (response) => response.leafNetwork[0].geometry },
  )

  const onHover = useCallback((event: MapLayerMouseEvent) => {
    const properties = event.features && event.features[0] && event.features[0]?.properties

    if (properties) {
      setHoverInfo({
        destinationGeometry: JSON.parse(properties.destinationGeometry) as GeoJSON.Polygon,
        destinationName: properties.destinationName,
        id: properties.id,
        lat: event.lngLat.lat,
        lng: event.lngLat.lng,
        miles: properties.miles?.toFixed(0),
        originGeometry: JSON.parse(properties.originGeometry) as GeoJSON.Polygon,
        originName: properties.originName,
      })
    } else {
      setHoverInfo(null)
    }
  }, [])

  if (isShipperNetworkLoading || isLeafNetworkLoading) {
    return <Spinner page />
  }

  return (
    <Map
      id='e2e-company-network'
      interactiveLayerIds={['shipperNetworkLayer']}
      mapboxAccessToken={import.meta.env.VITE_MAPBOX_API_KEY}
      onMouseMove={onHover}
    >
      <Source data={leafNetwork} id='leafNetwork' type='geojson'>
        <Layer
          id='leafNetworkLayer'
          paint={{
            'line-color': 'rgba(0, 200, 0, 0.2)',
            'line-width': 3,
          }}
          type='line'
        />
      </Source>

      <Source data={shipperNetwork} id='shipperNetwork' type='geojson'>
        <Layer
          id='shipperNetworkLayer'
          layout={{
            'line-sort-key': ['match', ['get', 'id'], hoverInfo?.id ?? '', 1, 0],
          }}
          paint={{
            'line-color': ['match', ['get', 'id'], hoverInfo?.id ?? '', 'black', 'red'],
            'line-width': 3,
          }}
          type='line'
        />

        {hoverInfo && (
          <Popup
            closeButton={false}
            closeOnClick={false}
            closeOnMove={false}
            latitude={hoverInfo.lat}
            longitude={hoverInfo.lng}
            offset={[0, -10] as [number, number]}
          >
            <Box
              sx={{
                alignItems: 'end',
                display: 'flex',
              }}
            >
              <Flag sx={{ color: 'blue' }} />

              <Typography>{hoverInfo.originName}</Typography>
            </Box>

            <Divider orientation='horizontal'>{hoverInfo.miles} mi</Divider>

            <Box
              sx={{
                alignItems: 'end',
                display: 'flex',
              }}
            >
              <Flag sx={{ color: 'red' }} />

              <Typography>{hoverInfo.destinationName}</Typography>
            </Box>
          </Popup>
        )}

        {hoverInfo && (
          <>
            <Source data={hoverInfo.originGeometry} id='shipperOriginNetwork' type='geojson'>
              <Layer
                id='shipperOriginNetworkLayer'
                paint={{
                  'fill-color': 'green',
                  'fill-opacity': 1,
                }}
                type='fill'
              />
            </Source>

            <Source
              data={hoverInfo.destinationGeometry}
              id='shipperDestinationNetwork'
              type='geojson'
            >
              <Layer
                id='shipperDestinationNetworkLayer'
                paint={{
                  'fill-color': 'blue',
                  'fill-opacity': 1,
                }}
                type='fill'
              />
            </Source>
          </>
        )}

        <MapLegend>
          <>
            <Square sx={{ color: 'red' }} />
            Your Network
          </>

          <>
            <Square sx={{ color: 'green' }} />
            Leaf&#39;s Network
          </>
        </MapLegend>
      </Source>
    </Map>
  )
}

export { CompanyNetwork }
