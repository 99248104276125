// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetAdaptCompanyDataQueryVariables = Types.Exact<{
  id: Types.Scalars['bigint']['input'];
}>;


export type GetAdaptCompanyDataQuery = { __typename?: 'query_root', adaptCompanyDataByPk?: { __typename?: 'adaptCompanyData', hasAdapt?: boolean, runDate?: any, windowEndDate?: any, windowStartDate?: any, data?: any } };



export const GetAdaptCompanyDataDocument = `
    query GetAdaptCompanyData($id: bigint!) {
  adaptCompanyDataByPk(companyId: $id) {
    hasAdapt
    runDate
    windowEndDate
    windowStartDate
    data
  }
}
    `;

export const useGetAdaptCompanyDataQuery = <
      TData = GetAdaptCompanyDataQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetAdaptCompanyDataQueryVariables,
      options?: UseQueryOptions<GetAdaptCompanyDataQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetAdaptCompanyDataQuery, TError, TData>(
      ['GetAdaptCompanyData', variables],
      fetcher<GetAdaptCompanyDataQuery, GetAdaptCompanyDataQueryVariables>(client, GetAdaptCompanyDataDocument, variables, headers),
      options
    )};

useGetAdaptCompanyDataQuery.document = GetAdaptCompanyDataDocument;

useGetAdaptCompanyDataQuery.getKey = (variables: GetAdaptCompanyDataQueryVariables) => ['GetAdaptCompanyData', variables];


useGetAdaptCompanyDataQuery.fetcher = (client: GraphQLClient, variables: GetAdaptCompanyDataQueryVariables, headers?: RequestInit['headers']) => fetcher<GetAdaptCompanyDataQuery, GetAdaptCompanyDataQueryVariables>(client, GetAdaptCompanyDataDocument, variables, headers);
