import { useEffect } from 'react'

import { useStore } from '@/store'
import type { Title } from '@/types'

const useTitles = (titles: Title | Title[]) => {
  const { titles: storedTitles, updateTitles } = useStore.getState()

  useEffect(() => {
    if (Array.isArray(titles)) {
      updateTitles(titles)
    } else {
      updateTitles([...storedTitles, titles])
    }
  }, [titles])
}

export { useTitles }
