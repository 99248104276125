// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetCompanyPortfolioQueryVariables = Types.Exact<{
  id: Types.Scalars['bigint']['input'];
}>;


export type GetCompanyPortfolioQuery = { __typename?: 'query_root', transportationPortfolios: Array<{ __typename?: 'transportationPortfolios', companyId?: any, name?: string, start?: any, greenPercTotal?: any, annualizedSpendGreen?: any, bluePercTotal?: any, annualizedSpendBlue?: any, yellowPercTotal?: any, annualizedSpendYellow?: any, percTotal?: any, annualizedSpend?: any, benefitToCustomer?: any }> };



export const GetCompanyPortfolioDocument = `
    query GetCompanyPortfolio($id: bigint!) {
  transportationPortfolios(where: {companyId: {_eq: $id}}, orderBy: {start: ASC}) {
    companyId
    name
    start
    greenPercTotal
    annualizedSpendGreen
    bluePercTotal
    annualizedSpendBlue
    yellowPercTotal
    annualizedSpendYellow
    percTotal
    annualizedSpend
    benefitToCustomer
  }
}
    `;

export const useGetCompanyPortfolioQuery = <
      TData = GetCompanyPortfolioQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetCompanyPortfolioQueryVariables,
      options?: UseQueryOptions<GetCompanyPortfolioQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetCompanyPortfolioQuery, TError, TData>(
      ['GetCompanyPortfolio', variables],
      fetcher<GetCompanyPortfolioQuery, GetCompanyPortfolioQueryVariables>(client, GetCompanyPortfolioDocument, variables, headers),
      options
    )};

useGetCompanyPortfolioQuery.document = GetCompanyPortfolioDocument;

useGetCompanyPortfolioQuery.getKey = (variables: GetCompanyPortfolioQueryVariables) => ['GetCompanyPortfolio', variables];


useGetCompanyPortfolioQuery.fetcher = (client: GraphQLClient, variables: GetCompanyPortfolioQueryVariables, headers?: RequestInit['headers']) => fetcher<GetCompanyPortfolioQuery, GetCompanyPortfolioQueryVariables>(client, GetCompanyPortfolioDocument, variables, headers);
