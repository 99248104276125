import { useState } from 'react'

import { Card, CardContent, Checkbox, FormControlLabel } from '@mui/material'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
  type TooltipItem,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

import { CardHeader, DataMissing, Spinner } from '@leaf/components'
import { ContractColors } from '@leaf/constants'
import { time, toBigMoney } from '@leaf/utilities'

import { graphqlClient } from '@/api'
import {
  type GetCompanyPortfolioQuery,
  useGetCompanyPortfolioQuery,
} from '@/features/company/company-overview-portfolio.api.generated'
import { useStore } from '@/store'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const LINE_COLOR = '#5000FA'
const BAR_COLOR = '#83C1FF'

const chartOptions = {
  plugins: {
    tooltip: {
      callbacks: {
        label: (context: TooltipItem<'bar'>) => {
          const { datasetIndex } = context
          const isLineChart = datasetIndex !== 0
          const raw = context.raw as number

          if (isLineChart) {
            return `${raw.toFixed(0)}%`
          }
          return toBigMoney(raw * 1e6) ?? undefined
        },
      },
    },
  },
  responsive: true,
  scales: {
    x: {
      grid: {
        display: false,
      },
      stacked: true,
      title: { display: true, text: 'Phase start date' },
    },
    y: {
      stacked: true,
      ticks: {
        callback: (v: number) => `${v}%`,
        stepSize: 25,
      },
      title: {
        display: true,
        font: { size: 11, weight: 'bold' },
        text: '% of Portfolio Leaf Activated (Annualized Spend)',
      },
    },
    y2: {
      position: 'right',
      stacked: true,
      ticks: {
        callback: (v: number) => toBigMoney(v * 1e6),
        display: true,
        stepSize: 25,
      },
      title: {
        display: true,
        font: { weight: 'bold' },
        text: 'Potential Annual Savings',
      },
    },
  },
}

const getChartData = (
  showTiers: boolean,
  portfolios: GetCompanyPortfolioQuery['transportationPortfolios'] = [],
) => {
  if (!portfolios.length) {
    return {
      datasets: [],
      labels: [],
    }
  }

  const labels = portfolios.map(({ start }) => time.toTwoLetterMonthYear(start))
  const lineValues = portfolios.map(({ benefitToCustomer }) => benefitToCustomer)

  let accountPlanPhases = []

  if (showTiers) {
    const barChartDataGreen = portfolios.map(({ greenPercTotal }) => greenPercTotal * 100)
    const barChartDataBlue = portfolios.map(({ bluePercTotal }) => bluePercTotal * 100)
    const barChartDataYellow = portfolios.map(({ yellowPercTotal }) => yellowPercTotal * 100)

    accountPlanPhases = [
      { color: LINE_COLOR, data: lineValues, label: 'Targeted Savings' },
      {
        color: ContractColors.GREEN,
        data: barChartDataGreen,
        label: 'Forward Contracted',
      },
      { color: ContractColors.BLUE, data: barChartDataBlue, label: 'Routing Guide' },
      { color: ContractColors.YELLOW, data: barChartDataYellow, label: 'Spot' },
    ]
  } else {
    accountPlanPhases = [
      { color: LINE_COLOR, data: lineValues, label: 'Targeted Savings' },
      {
        color: LINE_COLOR,
        data: portfolios.map(({ percTotal }) => percTotal * 100),
        label: 'Annual Spend',
      },
    ]
  }

  const datasets = accountPlanPhases.map(({ color, data, label }, index) => {
    const isLineChart = index === 0
    if (isLineChart) {
      return {
        backgroundColor: color,
        borderColor: color,
        data: lineValues,
        fill: false,
        label,
        type: 'line',
        yAxisID: 'y2',
      }
    }
    return {
      backgroundColor: showTiers ? color : BAR_COLOR,
      borderColor: showTiers ? color : BAR_COLOR,
      data,
      fill: 'origin',
      label,
      lineTension: 0.1,
      pointRadius: 0.8,
    }
  })

  return {
    datasets,
    labels,
  }
}

const CompanyOverviewPortfolio = () => {
  const user = useStore((state) => state.user)

  const [showTiers, setShowTiers] = useState(false)

  const { data: portfolios, isLoading } = useGetCompanyPortfolioQuery(
    graphqlClient,
    {
      id: user.companyId!,
    },
    {
      select: (response) => response.transportationPortfolios,
    },
  )
  const hasData = Boolean(portfolios?.length)

  const toggleTiers = () => setShowTiers(!showTiers)

  if (isLoading) {
    return <Spinner page />
  }

  const chartData = getChartData(showTiers, portfolios)

  return (
    <Card>
      <CardHeader
        action={
          <FormControlLabel
            control={<Checkbox checked={showTiers} onChange={toggleTiers} />}
            disabled={!hasData}
            label='Show Tiers'
          />
        }
        title='Transportation Portfolio'
      />

      <CardContent>
        {hasData ? (
          // @ts-expect-error
          <Bar data={chartData} options={chartOptions} redraw />
        ) : (
          <DataMissing sx={{ marginTop: '2.5em' }} />
        )}
      </CardContent>
    </Card>
  )
}

export { CompanyOverviewPortfolio }
