// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetConstellationsOverviewQueryVariables = Types.Exact<{
  shipperId: Types.Scalars['bigint']['input'];
}>;


export type GetConstellationsOverviewQuery = { __typename?: 'query_root', constellations: Array<{ __typename?: 'constellations', id: any, name: string, constellationLanes: Array<{ __typename?: 'constellationLanes', equipmentClass: string, lane: { __typename?: 'analyticsLanes', companyId: any, originLocation?: { __typename?: 'analyticsLocations', name: string }, destinationLocation?: { __typename?: 'analyticsLocations', name: string } } }>, constellationNetworkMoves: Array<{ __typename?: 'constellationNetworkMoves', networkMoveId: any }> }> };



export const GetConstellationsOverviewDocument = `
    query GetConstellationsOverview($shipperId: bigint!) {
  constellations(
    where: {_or: [{shipperWhitelist: {_contains: [$shipperId]}}, {constellationLanes: {lane: {companyId: {_eq: $shipperId}}}}]}
    orderBy: {name: ASC}
  ) {
    id
    name
    constellationLanes {
      equipmentClass
      lane {
        companyId
        originLocation {
          name
        }
        destinationLocation {
          name
        }
      }
    }
    constellationNetworkMoves {
      networkMoveId
    }
  }
}
    `;

export const useGetConstellationsOverviewQuery = <
      TData = GetConstellationsOverviewQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetConstellationsOverviewQueryVariables,
      options?: UseQueryOptions<GetConstellationsOverviewQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetConstellationsOverviewQuery, TError, TData>(
      ['GetConstellationsOverview', variables],
      fetcher<GetConstellationsOverviewQuery, GetConstellationsOverviewQueryVariables>(client, GetConstellationsOverviewDocument, variables, headers),
      options
    )};

useGetConstellationsOverviewQuery.document = GetConstellationsOverviewDocument;

useGetConstellationsOverviewQuery.getKey = (variables: GetConstellationsOverviewQueryVariables) => ['GetConstellationsOverview', variables];


useGetConstellationsOverviewQuery.fetcher = (client: GraphQLClient, variables: GetConstellationsOverviewQueryVariables, headers?: RequestInit['headers']) => fetcher<GetConstellationsOverviewQuery, GetConstellationsOverviewQueryVariables>(client, GetConstellationsOverviewDocument, variables, headers);
