import { Grid } from '@mui/material'

import { Page, Progress } from '@leaf/components'
import { time, toBigMoney } from '@leaf/utilities'

import { graphqlClient } from '@/api'
import { useGetAdaptCompanyDataQuery } from '@/features/company/company-overview.api.generated'
import { CompanyOverviewMetrics } from '@/features/company/company-overview-metrics'
import { CompanyOverviewPortfolio } from '@/features/company/company-overview-portfolio'
import { useTitles } from '@/hooks'
import { useStore } from '@/store'

type MetricType = {
  missing: boolean
  name: string
  unit: string
  value: string
}

const formatMetric = (metric: MetricType) => {
  if (metric.missing || !metric.value) {
    return '-'
  }
  const n = parseFloat(metric.value)
  const v = n.toFixed(0)

  if (metric.unit === 'USD') {
    return toBigMoney(v)!
  }
  if (metric.unit === 'PERCENT') {
    return `${v}%`
  }
  if (metric.unit === 'kTONS CO2e') {
    return `${v}kt`
  }
  const formatter = Intl.NumberFormat('en-US')
  return formatter.format(n)
}

const CompanyOverview = () => {
  const user = useStore((state) => state.user)

  useTitles([{ label: 'Company Overview' }])

  const { data, isLoading } = useGetAdaptCompanyDataQuery(
    graphqlClient,
    { id: user.companyId },
    {
      select: (response) => {
        const company = response.adaptCompanyDataByPk
        const objectives = company?.data?.objectives || []
        const metrics = (company?.data?.metrics || []) as MetricType[]

        return {
          metrics: metrics.map((metric) => ({
            ...metric,
            value: formatMetric(metric),
          })),
          objectives,
          runDate: time.toLocalDate(company?.runDate),
          show:
            company?.runDate &&
            company?.windowStartDate &&
            company?.windowEndDate &&
            Boolean(metrics.length),
          windowEndDate: time.toLocalDate(company?.windowEndDate),
          windowStartDate: time.toLocalDate(company?.windowStartDate),
        }
      },
    },
  )

  return (
    <>
      <Progress />

      <Page loading={isLoading}>
        <Grid container item spacing={2} xs={12}>
          <Grid item xs={4}>
            <CompanyOverviewMetrics data={data!} shipperId={user.companyId!} />
          </Grid>

          <Grid item xs={8}>
            <CompanyOverviewPortfolio />
          </Grid>
        </Grid>
      </Page>
    </>
  )
}

export { CompanyOverview }
