import { type ReactNode, memo } from 'react'

import { Box } from '@mui/material'
import type { GridRowModel } from '@mui/x-data-grid-pro'
import { get } from 'lodash-es'
import { useShallow } from 'zustand/react/shallow'

import { Wrap } from '@leaf/components'
import { AsyncTable, Columns } from '@leaf/table'

import { graphqlClient } from '@/api'
import {
  type GetAdaptNetworkMovesQuery,
  useGetAdaptNetworkMovesQuery,
} from '@/features/adapt-network-move/adapt-network-move-overview.api.generated'
import { useTitles } from '@/hooks'
import { useStore } from '@/store'

const renderMultiLine =
  (field: string | string[], isNumber: boolean = false) =>
  ({ row }: GridRowModel) => {
    const data: (ReactNode | string)[] = []
    const hasLane = [!!row.lane0, !!row.lane1, !!row.lane2, !!row.lane3, !!row.lane4]
    if (Array.isArray(field)) {
      field.forEach((f, i) => {
        if (!hasLane[i]) {
          return null
        }
        if (i > 0) {
          data.push(<br />)
        }
        data.push(row[f]?.toString() ?? '-')
      })
    } else {
      const { lane0, lane1, lane2, lane3, lane4 } = row
      data.push(get(lane0, field) ?? '-')
      data.push(<br />)
      data.push(get(lane1, field) ?? '-')

      if (lane2) {
        data.push(<br />)
        data.push(get(lane2, field) ?? '-')

        if (lane3) {
          data.push(<br />)
          data.push(get(lane3, field) ?? '-')
        }

        if (lane4) {
          data.push(<br />)
          data.push(get(lane4, field) ?? '-')
        }
      }
    }
    if (isNumber) {
      return <Box sx={{ textAlign: 'right' }}>{data}</Box>
    }
    return <Wrap>{data}</Wrap>
  }

// TODO: LEAF-5120 Implement export for server-side tables
const AdaptNetworkMoveOverview = () => {
  const [user, changeTable, savedState] = useStore(
    useShallow((state) => [state.user, state.changeTable, state.adaptNetworkMoveOverview]),
  )

  useTitles([{ label: 'Adapt Network Moves' }])

  const columns = [
    Columns.Action(),
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 300,
    },
    {
      field: 'moveType',
      headerName: 'Move Type',
    },
    {
      field: '_origins',
      headerName: 'Origins',
      minWidth: 250,
      renderCell: renderMultiLine('originLocation.name'),
      sortable: false,
    },
    {
      field: '_destinations',
      headerName: 'Destinations',
      minWidth: 250,
      renderCell: renderMultiLine('destinationLocation.name'),
      sortable: false,
    },
    {
      field: '_companyMiles',
      filterable: false,
      headerName: 'Miles',
      minWidth: 125,
      renderCell: renderMultiLine('companyMiles', true),
      sortable: false,
      type: 'number',
    },
    {
      field: 'totalMiles',
      headerName: 'Total Miles',
      type: 'number',
    },
    {
      field: '_deadheads',
      filterable: false,
      headerName: 'Deadhead Miles',
      minWidth: 125,
      renderCell: renderMultiLine(
        ['deadheadL0L1', 'deadheadL1L2', 'deadheadL2L3', 'deadheadL3L4', 'deadheadFinal'],
        true,
      ),
      sortable: false,
      type: 'number',
    },
    {
      field: '_annualLoads',
      filterable: false,
      headerName: 'Loads',
      renderCell: renderMultiLine(
        ['l0AnnualLoads', 'l1AnnualLoads', 'l2AnnualLoads', 'l3AnnualLoads', 'l4AnnualLoads'],
        true,
      ),
      sortable: false,
      type: 'number',
    },
    {
      field: '_equipmentClasses',
      filterable: false,
      headerName: 'Equipment Classes',
      minWidth: 125,
      renderCell: renderMultiLine([
        'l0EquipmentClass',
        'l1EquipmentClass',
        'l2EquipmentClass',
        'l3EquipmentClass',
        'l4EquipmentClass',
      ]),
      sortable: false,
    },
    {
      field: 'batchDate',
      headerName: 'Batch Date',
      type: 'date',
    },
  ]

  const initialState = {
    columns: {
      columnVisibilityModel: {
        company: false,
      },
    },
  }

  return (
    <AsyncTable
      // @ts-expect-error
      columns={columns}
      filters={{
        computed: {
          _companies: [
            'lane0.company.name',
            'lane1.company.name',
            'lane2.company.name',
            'lane3.company.name',
            'lane4.company.name',
          ],
          _destinations: [
            'lane0.destinationLocation.name',
            'lane1.destinationLocation.name',
            'lane2.destinationLocation.name',
            'lane3.destinationLocation.name',
            'lane4.destinationLocation.name',
          ],
          _origins: [
            'lane0.originLocation.name',
            'lane1.originLocation.name',
            'lane2.originLocation.name',
            'lane3.originLocation.name',
            'lane4.originLocation.name',
          ],
        },
        preset: [{ companyId: { _eq: user.companyId } }],
      }}
      initialState={initialState}
      name='adaptNetworkMoveOverview'
      persist={{
        fn: changeTable,
        save: savedState,
      }}
      query={{
        client: graphqlClient,
        fn: useGetAdaptNetworkMovesQuery,
        select: (response: GetAdaptNetworkMovesQuery) => ({
          count: response?.analyticsNetworkMovesAggregate.aggregate?.count,
          rows: response?.analyticsNetworkMoves,
        }),
      }}
    />
  )
}

const AdaptNetworkMoveOverviewMemo = memo(AdaptNetworkMoveOverview)
export { AdaptNetworkMoveOverviewMemo as AdaptNetworkMoveOverview }
