// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetLanePlansQueryVariables = Types.Exact<{
  shipperId: Types.Scalars['bigint']['input'];
}>;


export type GetLanePlansQuery = { __typename?: 'query_root', lanePlans: Array<{ __typename?: 'lanePlans', id: any, shipperId: any, adaptLaneId?: any, plannedShipperRpm?: any, plannedStartDate?: any, plannedWeeklyVolume?: number, targetPercBlue?: any, targetPercDedicated?: any, targetPercGreen?: any, targetPercYellow?: any, pickupType?: string, deliveryType?: string, equipmentClass?: string, route: { __typename?: 'routingRoutes', id: any, legs: Array<{ __typename?: 'routingLegs', lane: { __typename?: 'routingLanes', id: any, destinationName?: string, originName?: string } }> }, adaptLanes: Array<{ __typename?: 'analyticsAdaptLanes', id: any, batchDate: any, equipmentClass: string }> }> };



export const GetLanePlansDocument = `
    query GetLanePlans($shipperId: bigint!) @cached(ttl: 300) {
  lanePlans(where: {_and: [{shipperId: {_eq: $shipperId}}]}) {
    id
    shipperId
    route {
      id
      legs {
        lane {
          id
          destinationName
          originName
        }
      }
    }
    adaptLaneId
    adaptLanes(orderBy: {batchDate: DESC}) {
      id
      batchDate
      equipmentClass
    }
    plannedShipperRpm
    plannedStartDate
    plannedWeeklyVolume
    targetPercBlue
    targetPercDedicated
    targetPercGreen
    targetPercYellow
    pickupType
    deliveryType
    equipmentClass
  }
}
    `;

export const useGetLanePlansQuery = <
      TData = GetLanePlansQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetLanePlansQueryVariables,
      options?: UseQueryOptions<GetLanePlansQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetLanePlansQuery, TError, TData>(
      ['GetLanePlans', variables],
      fetcher<GetLanePlansQuery, GetLanePlansQueryVariables>(client, GetLanePlansDocument, variables, headers),
      options
    )};

useGetLanePlansQuery.document = GetLanePlansDocument;

useGetLanePlansQuery.getKey = (variables: GetLanePlansQueryVariables) => ['GetLanePlans', variables];


useGetLanePlansQuery.fetcher = (client: GraphQLClient, variables: GetLanePlansQueryVariables, headers?: RequestInit['headers']) => fetcher<GetLanePlansQuery, GetLanePlansQueryVariables>(client, GetLanePlansDocument, variables, headers);
