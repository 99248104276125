import { useShallow } from 'zustand/react/shallow'

import { Columns, Table } from '@leaf/table'
import { route } from '@leaf/utilities'

import { graphqlClient } from '@/api'
import { useGetContractsQuery } from '@/features/contract/contract-overview.api.generated'
import { useTitles } from '@/hooks'
import { useStore } from '@/store'

const ContractOverview = () => {
  const [user, changeTable, savedState] = useStore(
    useShallow((state) => [state.user, state.changeTable, state.contractOverview]),
  )

  useTitles([{ label: 'Contracts' }])

  const { data, isLoading } = useGetContractsQuery(
    graphqlClient,
    { shipperId: user.companyId },
    {
      select: ({ contracts }) =>
        contracts.map((contract) => ({
          ...contract,
          moveType: contract.contractRoutes[0]?.route?.tripType,
          nRoutes: contract.contractRoutes?.length ?? 0,
          ...route.getOriginsDestinations(contract.contractRoutes),
        })),
    },
  )

  const columns = [
    Columns.Action(),
    { field: 'id', headerName: 'ID' },
    { field: 'contractType', headerName: 'Contract Type' },
    { field: 'maxVolume', headerName: 'Weekly Volume', minWidth: 200, type: 'number' },
    { field: 'equipmentType.name', headerName: 'Equipment Type' },
    {
      field: 'moveType',
      headerName: 'Move Type',
    },
    { field: 'contractAccessorials', headerName: 'Accessorials', type: 'list' },
    {
      field: 'nRoutes',
      headerName: '# Routes',
      type: 'number',
    },
    {
      field: 'origins',
      headerName: 'Origins',
      type: 'list',
    },
    {
      field: 'destinations',
      headerName: 'Destinations',
      type: 'list',
    },
    { field: 'startDate', headerName: 'Start Date', type: 'date' },
    { field: 'endDate', headerName: 'End Date', type: 'date' },
    { field: 'linehaulRpm', headerName: 'Linehaul RPM', type: 'money' },
  ]

  const initialState = {
    columns: {
      columnVisibilityModel: {
        'contractAccessorials': false,
        'contractType': false,
        'equipmentType.name': false,
        'moveType': false,
        'nRoutes': false,
      },
    },
  }

  const exportOptions = { fileName: 'contracts' }

  return (
    <Table
      // @ts-expect-error
      columns={columns}
      exportOptions={exportOptions}
      initialState={initialState}
      loading={isLoading}
      name='contractOverview'
      persist={{
        fn: changeTable,
        save: savedState,
      }}
      rows={data}
    />
  )
}

export { ContractOverview }
