import { useParams } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'

import { DataRow, DataRowList, Page, Progress, SubCard, Tabs } from '@leaf/components'
import { useTabs } from '@leaf/hooks'
import { route, time, toMoney } from '@leaf/utilities'

import { graphqlClient } from '@/api'
import { CONTAINER_TABLE_HEIGHT } from '@/constants'
import {
  type ContractDetailsQueryReturnType,
  contractDetailsQuery,
} from '@/features/contract/contract-details.api'
import type { ShipmentType } from '@/features/contract/contract-details.types'
import { ContractDetailsMatches } from '@/features/contract/contract-details-matches'
import { useGetContractMatchesQuery } from '@/features/contract/contract-details-matches.api.generated'
import { ContractDetailsRoutes } from '@/features/contract/contract-details-routes'
import { ContractDetailsShipments } from '@/features/contract/contract-details-shipments'
import {
  type GetContractShipmentsQuery,
  useGetContractShipmentsQuery,
} from '@/features/contract/contract-details-shipments.api.generated'
import { useTitles } from '@/hooks'

type LocationType =
  | GetContractShipmentsQuery['shipmentTenders'][0]['destination']
  | GetContractShipmentsQuery['shipmentTenders'][0]['origin']

const getLocation = (location?: LocationType) => {
  if (!location) {
    return []
  }
  return [
    `${location.city}, ${location.state} ${location.zip}`,
    location.street,
    location.businessName,
  ]
}

const ContractDetails = () => {
  const { contractId: id } = useParams()

  const { activeTab, setActiveTab } = useTabs()

  const { data } = useQuery(contractDetailsQuery(id!))
  const contract = data as ContractDetailsQueryReturnType
  const routes = contract.contractRoutes

  useTitles([{ label: 'Contracts', to: 'contracts' }, { label: id! }])

  const { data: matches, isLoading: isMatchesLoading } = useGetContractMatchesQuery(
    graphqlClient,
    { id },
    {
      select: ({ routingGuides }) =>
        routingGuides.map((routingGuide) => ({
          ...routingGuide,
          ...route.getOriginsDestinations(routingGuide.sellContract.contractRoutes),
        })),
      staleTime: 1800000,
    },
  )

  const { data: shipments, isLoading: isShipmentsLoading } = useGetContractShipmentsQuery(
    graphqlClient,
    { id },
    {
      select: ({ shipmentTenders }) =>
        shipmentTenders.map(({ destination, origin, ...rest }) => ({
          ...rest,
          destination: getLocation(destination),
          origin: getLocation(origin),
          pickupDate:
            rest.originAppointmentTime ??
            rest.originInitialAppointmentTime ??
            rest.expectedShipmentDate,
        })) as ShipmentType[],
      staleTime: 1800000,
    },
  )

  return (
    <>
      <Progress />

      <Tabs
        activeTab={activeTab}
        changeTab={setActiveTab}
        id='e2e-contract-details-navigation'
        tabs={['Details', 'Routes', 'Matches', 'Shipments']}
      />

      <Page id={`e2e-contract-details-tab-${activeTab}`} sx={{ height: 'auto' }}>
        {activeTab === 0 && (
          <>
            <SubCard title='General' xs={4}>
              <DataRow label='Type' value={contract.contractType} />

              <DataRow label='Status' value={contract.contractStatus} />

              <DataRow label='Equipment' value={contract.equipmentType.name} />

              <DataRowList label='Accessorials' value={contract.accessorials} />
            </SubCard>

            <SubCard title='Timeframe & Volume' xs={4}>
              <DataRow label='Start Date' value={time.toLocalDate(contract.startDate)} />

              <DataRow label='End Date' value={time.toLocalDate(contract.endDate)} />

              <DataRow label='Volume Type' value={contract.volumeType} />

              <DataRow label='Volume Frequency' value={contract.volumeFrequency} />

              <DataRow label='Minimum Volume' value={contract.minVolume} />

              <DataRow label='Maximum Volume' value={contract.maxVolume} />

              <DataRow label='Include Weekends' value={contract.includeWeekends} />
            </SubCard>

            <SubCard title='Pricing' xs={4}>
              <DataRow label='Pricing Mechanism' value={contract.pricingMechanism} />

              <DataRow label='Linehaul RPM' value={toMoney(contract.linehaulRpm)} />

              <DataRow
                label='Fuel Surcharge Schedule'
                value={contract.fuelSurchargeSchedule?.name}
              />
            </SubCard>
          </>
        )}

        {activeTab === 1 && <ContractDetailsRoutes data={routes} show={activeTab === 1} />}

        {activeTab === 2 && (
          <SubCard sx={{ height: CONTAINER_TABLE_HEIGHT, padding: 0 }}>
            <ContractDetailsMatches data={matches} loading={isMatchesLoading} />
          </SubCard>
        )}

        {activeTab === 3 && (
          <SubCard sx={{ height: CONTAINER_TABLE_HEIGHT, padding: 0 }}>
            <ContractDetailsShipments data={shipments || []} loading={isShipmentsLoading} />
          </SubCard>
        )}
      </Page>
    </>
  )
}

export { ContractDetails }
