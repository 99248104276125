import { Box, Typography } from '@mui/material'

import { CompanyAvatar, Route } from '@leaf/components'

import type { RouteLaneType } from '@/features/adapt-network-move/adapt-network-move-details.types'
import { useStore } from '@/store'

type LaneProps = {
  color: string
  data: RouteLaneType
  position: number
}
const AdaptNetworkMoveRouteScheduleLane = ({ color, data, position }: LaneProps) => {
  const user = useStore((state) => state.user)
  const hideLogo = !user.isAdmin && parseInt(data.company.id, 10) !== user.companyId

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
      }}
    >
      <Route.RouteStyled $color={color} $position={position} />

      <Route.LaneStyled>
        <Typography variant='body2'>{data.originLocation.name}</Typography>

        <Box>
          <Route.DistanceStyled>
            <Box gap={8} sx={{ display: 'flex' }}>
              {data.companyMiles ? (
                <Typography variant='caption'>{data.companyMiles} miles</Typography>
              ) : (
                <Typography variant='caption'>-</Typography>
              )}
            </Box>

            <Route.HorizontalLineStyled />

            <Box sx={{ marginLeft: '2', marginRight: '2' }}>
              <CompanyAvatar id={hideLogo ? -1 : data.company.id} />
            </Box>
          </Route.DistanceStyled>
        </Box>

        <Typography variant='body2'>{data.destinationLocation.name}</Typography>
      </Route.LaneStyled>
    </Box>
  )
}

export { AdaptNetworkMoveRouteScheduleLane }
