import { ChangelogDialog } from '@leaf/components'
import type * as Types from '@leaf/types'

import log_202401 from '@/features/changelog/log-202401.md?raw'
import log_202402 from '@/features/changelog/log-202402.md?raw'
import log_202403 from '@/features/changelog/log-202403.md?raw'
import log_202404 from '@/features/changelog/log-202404.md?raw'
import log_202405 from '@/features/changelog/log-202405.md?raw'
import log_202406 from '@/features/changelog/log-202406.md?raw'
import log_202407 from '@/features/changelog/log-202407.md?raw'

const changelogs = [
  log_202407,
  log_202406,
  log_202405,
  log_202404,
  log_202403,
  log_202402,
  log_202401,
]

const Changelog = ({ onClose, open }: Types.Dialog) => {
  return <ChangelogDialog changelogs={changelogs} onClose={onClose} open={open} />
}

export { Changelog }
