import { clean } from '@leaf/form'
import type * as Types from '@leaf/types'

import { graphqlClient } from '@/api'
import {
  GetContractByIdDocument,
  type GetContractByIdQuery,
} from '@/features/contract/contract-details.api.generated'

type ContractDetailsQueryReturnType = Types.NoUndefined<
  {
    accessorials: string[]
  } & GetContractByIdQuery['contractsByPk']
>

const contractDetailsQuery = (id: string) => ({
  queryFn: async () => {
    const response = await graphqlClient.request(GetContractByIdDocument, { id })
    const data = clean(response) as GetContractByIdQuery
    const contract = (data.contractsByPk || {}) as ContractDetailsQueryReturnType
    return {
      ...contract,
      accessorials: contract.contractAccessorials.map(
        (contractAccessorial) => contractAccessorial.accessorial.name,
      ),
    }
  },
  queryKey: [`contract-details-${id}`],
  staleTime: 1800000,
})

export { contractDetailsQuery }
export type { ContractDetailsQueryReturnType }
