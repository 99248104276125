import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'

import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3'
import { LicenseInfo } from '@mui/x-license'
import { QueryClientProvider } from '@tanstack/react-query'
import { Chart as ChartJS, registerables } from 'chart.js'
import AnnotationPlugin from 'chartjs-plugin-annotation'

import { Sentry } from '@leaf/integration'
import { CustomThemeProvider } from '@leaf/theme'

import * as api from '@/api'
import { router } from '@/navigation'

import 'chartjs-adapter-date-fns'
import 'mapbox-gl/dist/mapbox-gl.css'

ChartJS.register(...registerables)
ChartJS.register(AnnotationPlugin)
ChartJS.defaults.font.family = 'Avenir'

Sentry.init(import.meta.env.VITE_SENTRY_DSN, import.meta.env.DEV)
LicenseInfo.setLicenseKey(import.meta.env.VITE_MUIX_PRO_LICENSE_KEY)

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <QueryClientProvider client={api.queryClient}>
      <CustomThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <RouterProvider router={router} />
        </LocalizationProvider>
      </CustomThemeProvider>
    </QueryClientProvider>
  </StrictMode>,
)
