import { Navigate } from 'react-router-dom'
import type { RouteObject } from 'react-router-dom'

import { Sentry } from '@leaf/integration'

import {
  AdaptLaneOverview,
  AdaptNetworkMoveDetails,
  AdaptNetworkMoveOverview,
  CompanyOverview,
  ConstellationDetails,
  ConstellationOverview,
  ContractDetails,
  ContractDetailsShipmentsDetails,
  ContractOverview,
  DashboardCategory,
  LaneExplorerView,
  Policies,
  adaptNetworkMoveDetailsLoader,
  constellationDetailsLoader,
  contractDetailsLoader,
  shipmentDetailsLoader,
} from '@/features'
import { CompanyNetwork } from '@/features/company/company-network'
import { DEFAULT_ROUTE } from '@/navigation/default-route'
import { ErrorPage } from '@/navigation/pages/error-page'
import { LayoutPage } from '@/navigation/pages/layout-page'
import { NotFoundPage } from '@/navigation/pages/not-found-page'

const publicRoutes: never[] = []

// Redirects for renamed routes, delete after 3 months
const redirectRoutes: never[] = []

const privateRoutes: RouteObject[] = [
  { element: <Navigate to={DEFAULT_ROUTE} />, path: '/' },

  // Dashboard
  {
    Component: DashboardCategory,
    path: 'dashboard',
  },
  {
    Component: DashboardCategory,
    path: 'dashboard/:category',
  },

  // Company
  {
    children: [
      {
        Component: CompanyOverview,
        index: true,
        path: 'overview',
      },
      {
        Component: CompanyNetwork,
        path: 'network',
      },
    ],
    path: 'company',
  },

  // Adapt
  {
    children: [
      {
        children: [
          {
            Component: AdaptLaneOverview,
            index: true,
          },
          {
            Component: LaneExplorerView,
            path: ':id/explorer',
          },
        ],
        path: 'lanes',
      },
      {
        children: [
          {
            Component: AdaptNetworkMoveOverview,
            index: true,
          },
          {
            Component: AdaptNetworkMoveDetails,
            loader: adaptNetworkMoveDetailsLoader,
            path: ':id',
          },
        ],
        path: 'network-moves',
      },
      {
        children: [
          {
            Component: ConstellationOverview,
            index: true,
          },
          {
            Component: ConstellationDetails,
            loader: constellationDetailsLoader,
            path: ':id',
          },
        ],
        path: 'constellations',
      },
    ],
    path: 'adapt',
  },

  // Contracts
  {
    children: [
      {
        Component: ContractOverview,
        index: true,
      },
      {
        children: [
          {
            Component: ContractDetails,
            index: true,
            loader: contractDetailsLoader,
          },
          {
            Component: ContractDetailsShipmentsDetails,
            loader: shipmentDetailsLoader,
            path: 'shipments/:shipmentId',
          },
        ],
        path: ':contractId',
      },
    ],
    path: 'contracts',
  },

  {
    Component: Policies,
    path: 'policies',
  },
]

const router = Sentry.createRouter([
  ...publicRoutes,
  {
    Component: LayoutPage,
    ErrorBoundary: ErrorPage,
    children: [
      {
        Component: NotFoundPage,
        path: '*',
      },
      {
        ErrorBoundary: ErrorPage,
        children: privateRoutes,
      },
      ...redirectRoutes,
    ],
    id: 'root',
    path: '/',
  },
])

export { router }
