// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetShippersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetShippersQuery = { __typename?: 'query_root', companies: Array<{ __typename?: 'companies', id: any, name: string }> };



export const GetShippersDocument = `
    query GetShippers {
  companies(orderBy: {name: ASC}, where: {companyType: {_eq: "SHIPPER"}}) {
    id
    name
  }
}
    `;

export const useGetShippersQuery = <
      TData = GetShippersQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetShippersQueryVariables,
      options?: UseQueryOptions<GetShippersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetShippersQuery, TError, TData>(
      variables === undefined ? ['GetShippers'] : ['GetShippers', variables],
      fetcher<GetShippersQuery, GetShippersQueryVariables>(client, GetShippersDocument, variables, headers),
      options
    )};

useGetShippersQuery.document = GetShippersDocument;

useGetShippersQuery.getKey = (variables?: GetShippersQueryVariables) => variables === undefined ? ['GetShippers'] : ['GetShippers', variables];


useGetShippersQuery.fetcher = (client: GraphQLClient, variables?: GetShippersQueryVariables, headers?: RequestInit['headers']) => fetcher<GetShippersQuery, GetShippersQueryVariables>(client, GetShippersDocument, variables, headers);
