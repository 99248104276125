import { memo } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { Columns, Table } from '@leaf/table'
import { route } from '@leaf/utilities'

import { graphqlClient } from '@/api'
import { useGetLanePlansQuery } from '@/features/adapt-lane/adapt-lane-overview.api.generated'
import { useTitles } from '@/hooks'
import { useStore } from '@/store'

const AdaptLaneOverview = () => {
  const [user, changeTable, savedState] = useStore(
    useShallow((state) => [state.user, state.changeTable, state.adaptLaneOverview]),
  )

  useTitles([{ label: 'Adapt Lanes' }])

  const { data: lanes, isLoading } = useGetLanePlansQuery(
    graphqlClient,
    { shipperId: user.companyId },
    {
      select: (response) => {
        return response.lanePlans.map((lanePlan) => {
          const relevantAdaptLanes =
            lanePlan.adaptLanes?.filter((adaptLane) => {
              return adaptLane.equipmentClass === lanePlan.equipmentClass
            }) || []
          const latestAdaptLane = relevantAdaptLanes[0]

          return {
            ...lanePlan,
            batchDate: latestAdaptLane?.batchDate,
            ...route.getOriginsDestinations([lanePlan]),
          }
        })
      },
    },
  )

  const columns = [
    Columns.LaneExplorer({ id: 'adaptLaneId' }),
    {
      field: 'id',
      headerName: 'ID',
    },
    {
      field: 'origins',
      headerName: 'Origins',
      minWidth: 250,
      sortable: false,
    },
    {
      field: 'destinations',
      headerName: 'Destinations',
      minWidth: 250,
      sortable: false,
    },
    {
      field: 'equipmentClass',
      headerName: 'Equipment Class',
    },
    {
      field: 'pickupType',
      headerName: 'Pickup Type',
    },
    {
      field: 'deliveryType',
      headerName: 'Delivery Type',
    },
    {
      field: 'plannedWeeklyVolume',
      headerName: 'Weekly Volume',
      type: 'number',
    },
    {
      field: 'plannedShipperRpm',
      headerName: 'RPM',
      type: 'money',
    },
    {
      field: 'plannedStartDate',
      headerName: 'Start Date',
      type: 'date',
    },
    {
      field: 'batchDate',
      headerName: 'Adapt Date',
      type: 'date',
    },
    {
      field: 'targetPercDedicated',
      headerName: 'Dedicated',
      type: 'percent',
    },
    {
      field: 'targetPercYellow',
      headerName: 'Yellow',
      type: 'percent',
    },
    {
      field: 'targetPercBlue',
      headerName: 'Blue',
      type: 'percent',
    },
    {
      field: 'targetPercGreen',
      headerName: 'Green',
      type: 'percent',
    },
  ]

  const initialState = {
    columns: {
      columnVisibilityModel: {
        'deliveryType.id': false,
        'equipmentClass.id': false,
        'id': false,
        'pickupType.id': false,
        'route.id': false,
      },
    },
  }

  return (
    <Table
      // @ts-expect-error
      columns={columns}
      initialState={initialState}
      loading={isLoading}
      name='adaptLaneOverview'
      persist={{
        fn: changeTable,
        save: savedState,
      }}
      rows={lanes || []}
    />
  )
}

const AdaptLaneOverviewMemo = memo(AdaptLaneOverview)
export { AdaptLaneOverviewMemo as AdaptLaneOverview }
